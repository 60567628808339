<script setup lang="ts">
// TODO:
// - Add a size prop and make it match the size of the BaseAvatar component.
// - We currently have a "circle" avatar, but we also want to display the logo full width, without being cut off.
//   because in a circle, there's always a risk of cutting off the logo.
// - We should decide between a separate component for the full width logo, or adding something like a "shape" or "variant" prop.
// - Also, it would be really awesome if we could detect the main color and then use that as the border color.
//   (but that's something for the backend)

interface OrganizionLogoProps {
  name: string
  logoUrl?: string
  profileColor?: string
  status?: OrganizationStatus
}

const props = withDefaults(
  defineProps<{
    organization: OrganizionLogoProps
    squareLogo?: boolean
    showTooltip?: boolean
  }>(),
  {
    // Wether to show the logo as a square/circle icon or full width.
    squareLogo: true,
    showTooltip: true,
  },
)

const isArchived = computed(() => props.organization.status == OrganizationStatus.Archived)

// This is uggly.
// If we want't to show the logo full width, we're not showing it as an avatar.
// And when it doesn't have a logo url, we're not showing it as an avatar either.
const asSquareLogo = computed(() => !props.organization.logoUrl || props.squareLogo)

// take the first 2 chars of the organization name for now
const initials = computed(() => props.organization.name?.substring(0, 2) || '')

const colorClass = computed(() => {
  return props.organization.profileColor || getRandomColor()
})

// TODO: this doesn't work yet, need to figure out something else.
// tooltip should also be modified.
const statusClass = computed(() => {
  if (isArchived.value) {
    return 'border-danger-200 dark:border-danger-700'
  }
  return ''
})

const logoStylingClass = computed(() => {
  'border-muted-200 dark:border-muted-700 max-w-[400px] rounded-lg border-2 border-solid'
})

</script>

<template>
  <BaseAvatar
    v-if="!props.organization.logoUrl"
    :text="initials"
    :class="[colorClass, statusClass]"
    :data-nui-tooltip="props.organization.name"
  />
  <div
    v-else-if="props.organization.logoUrl"
    class="border-primary-500 relative flex size-10 items-center justify-center overflow-hidden rounded-full border-2"
  >
    <img
      :src="props.organization.logoUrl || undefined"
      :text="initials"
      class=""
      :class="[asSquareLogo && 'aspect-square', statusClass, logoStylingClass]"
      :data-nui-tooltip="props.organization.name"
      :alt="`${props.organization.name} logo`"
    >
  </div>
</template>
